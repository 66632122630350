import {Module} from "vuex";

export type AiToolKitItem = {
    type: string,
    fromText: string,
    name: string,
    img1: string,
    img2: string,
    from: string,
    url: string
}
interface AiToolKitState {
    currentTool: AiToolKitItem,
    status: string,
    loadState: boolean,
    koutuImage: HTMLImageElement,
    koutuBase64: string
}

export const AiToolKit:Module<AiToolKitState, any> = {
    namespaced: true,
    state: {
        currentTool: null,
        status: `normal`,
        loadState: false,
        koutuImage: null,
        koutuBase64: ``
    },
    mutations: {
        setCurrentTool ( state, payload:AiToolKitItem ) {
            state.currentTool = payload ;
        },
        setStatus ( state, payload:string ) {
            state.status = payload ;
        },
        setLoadState ( state, payload:boolean ) {
            state.loadState = payload ;
        },
        setKoutuImage ( state, payload:{image: HTMLImageElement, base64: ``} ) {
            state.koutuImage = payload.image ;
            state.koutuBase64 = payload.base64 ;
        }
    },
    actions: {}
}
