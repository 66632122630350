import axios, { CancelToken } from "axios";
import {ServerAiPaintingApi, ServerAiExpand} from "../constants/servers";
import {convertUrlToImage, convertImageToBlob} from "@scripts/tools.ts";

/**
 * 上传图片
 */
export const uploadReferenceImg = async (
    url: string,
    token:string,
    opts?: {
        cancelToken?: CancelToken,
        attrs?: { [key: string]: any },
        type?: string,
        quality?: number
    }
) => {
    try {
        const cancelToken = opts?.cancelToken ;
        const { type = `image/jpeg`, quality = 0.8 } = opts ?? {} ;
        const img = await convertUrlToImage ( url, opts ) ;
        const file = await convertImageToBlob ( img, { type, quality } ) ;
        console.log (`uploadReferenceImg,${file.type},${file.size/1000}kb` ) ;
        const formData = new FormData () ;
        formData.append ( `token`, token ) ;
        formData.append ( `file`, file ) ;
        const res = await axios ( {
            method: `POST`,
            url: ServerAiPaintingApi + `/aiPainting/task/uploadImg`,
            headers: {
                "Content-Type": `multipart/form-data`
            },
            cancelToken: cancelToken,
            data: formData
        } ) ;
        if ( res.data.code === 200 ) {
            return [null, res.data.data] ;
        } else {
            return [new Error ( res.data.message ), null] ;
        }
    } catch ( e ) {
        return [e, null] ;
    }
} ;
/**
 * 阿里图片分割
 * @see http://doc.bigbigwork.com/docs/ai_hua/5475
 * @param payload {imgUrl: string, action: string, token: string} 图片地址，操作类型，token
 */
export const fetchAliSegment = async ( payload: {
    img_url: string,
    action: string,
    token: string,
    flag_from?: string,
    cancelToken?: CancelToken
}) => {
    try {
        const formData = new FormData () ;
        formData.append ( `img_url`, payload.img_url ) ;
        formData.append ( `action`, payload.action ) ;
        formData.append ( `token`, payload.token ) ;
        formData.append ( `flag_from`, payload.flag_from || `dz` ) ;
        const res = await axios ( {
            method: `POST`,
            url: `${ServerAiPaintingApi}/segment`,
            data: formData,
            cancelToken: payload.cancelToken
        } ) ;
        if ( [200, 1402].includes ( res.data.code ) ) {
            return [null, res.data] ;
        } else {
            return [new Error ( res.data.message ), res.data] ;
        }
    } catch ( e ) {
        return [e, null] ;
    }
} ;
/*
* 上传抠图结果，获取其oss 地址
* @see http://doc.bigbigwork.com/docs/aih2/7867
* */
export const uploadSegmentResult = async ( payload: { file: File, user_token: string } ) => {
    const { file, user_token } = payload ?? {} ;
    try {
        const formData = new FormData () ;
        formData.append ( `file`, file ) ;
        formData.append ( `user_token`, user_token ) ;
        formData.append (`callLocation`, `pc-扩大图` ) ;
        const res = await axios ( {
            method: `POST`,
            url: `${ServerAiExpand}/upscale/img/uploadImg`,
            data: formData
        } ) ;
        if ( res.data.code === 200 ) {
            return [null, res.data.data] ;
        } else {
            return [new Error ( res.data.message ), res.data] ;
        }
    } catch ( e ) {
        return [e, null] ;
    }
}
